@font-face {
  font-family: american_typewriter;
  src: url(/fonts/AMERICAN_TYPEWRITER_REGULAR.TTF);
}

* {
  box-sizing: border-box;
  list-style-type: none;
}

body {
  margin: 0;
  padding: 0;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.main-wrapper {
  width: 1140px;
  margin: auto;
  padding: 40px 0;
}

.main-wrapper .ting-logo {
  text-align: center;
  margin: 20px 0 60px 0;
}

.logo-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin: 0px 50px;
  margin-left: 64px;
}

.logo-section .logo-inside {
  width: 30%;
  padding-right: 30px;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.logo-section  p {
  font-family: american_typewriter;
  font-size: 18px;
  color: #000000;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 70px;
}

.logo-section .logo-inside a {
  text-decoration: none;
}

.logo-img {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}

.close {
  font-size: 20px;
}

iframe {
  display: block;
}

.logo-section .card{
  width: 30%;
}

.logo-section .card .pic {
  width: 348px;
  padding: 0px 10px;
}

@media screen and (max-width: 1200px) {
  .logo-section{
    margin: 0px 0px;
    margin-left: 15px;
  }
  
  .main-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .logo-section .logo-inside {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .logo-section .logo-inside {
    width: 100%;
  }

  .logo-img {
    min-height: auto;
  }
}

.tab .button_section h5 {
  display: flex;
  padding: 0 20px;
  padding: 20px 0;
  justify-content: center;
  background-color: #fae90c;
  flex-wrap: wrap;
}

.tab .button_section h5 span {
  color: #000;
  margin: 0 18px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  font-family: american_typewriter;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .tab .button_section h5 {
    display: block;
    overflow: scroll;
    white-space: nowrap;
    padding: 10px 20px;
  }

  .tab .button_section h5 span {
    margin: 0;
    padding: 10px 13px;
    text-align: center;
    display: inline-block;
    border-bottom: 2px solid transparent;
    font-size: 16px;
  }
}

.tab .button_section .active {
  border-bottom: 2px solid #000;
}

.categories {
  display: none;
}
.categories.active {
  display: block;
}
